import React from 'react'
import { Button, Select, Searchbar, Section, Flex } from '@exivity/ui'

import ExportGroup from '../ExportGroup'
import PageNavigation from '../../molecules/PageNavigation'

interface TableControlsProps<T> {
  pageSize: number
  data: T[]
  onPageSizeUpdate?: (update: number) => void
  onSearchTermUpdate?: (update: string) => void
  onCreate?: () => void
  pageNum: number
  maxPage: number
  onPageNumUpdate: (update: number) => void
}

// eslint-disable-next-line
function TableControls<T>({
  pageSize,
  data,
  onPageSizeUpdate,
  onSearchTermUpdate,
  onCreate,
  pageNum,
  maxPage,
  onPageNumUpdate
}: TableControlsProps<T>) {
  const onPageAmountChange = (update: string) => {
    if (onPageSizeUpdate) {
      onPageSizeUpdate(Number(update))
    }
  }

  const onSearchTermChange = (update: string) => {
    if (onSearchTermUpdate) {
      onSearchTermUpdate(update)
    }
  }

  return (
    <div className='table-controls'>
      <Section>
        <Flex justifyContent='space-between'>
          {onCreate ? <Button.Create small onClick={onCreate} /> : <div />}
          <ExportGroup data={data} />
        </Flex>
      </Section>
      <Section>
        <Flex justifyContent='space-between'>
          <Select
            value={pageSize.toString()}
            data={['10', '25', '50', '100']}
            onChange={onPageAmountChange} />
          <PageNavigation page={pageNum} max={maxPage} setPage={onPageNumUpdate} />
          <Searchbar
            bg='transparent !important'
            onChange={onSearchTermChange} />
        </Flex>
      </Section>
    </div>
  )
}

export default TableControls
