import React, { useState } from 'react'
import { Heading, Input, Form, Button } from '@exivity/ui'

interface LoginFormProps {
  onSubmit: (email: string, password: string) => void
}

export default function LoginForm ({ onSubmit }: LoginFormProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Form className='login-form'
      onSubmit={e => {
        onSubmit(email, password)
        e.preventDefault()
      }}>
      <Heading>Login</Heading>
      <Input
        required
        name='email'
        placeholder='email'
        textAlign='start'
        value={email}
        onChange={setEmail} />
      <br />
      <Input
        required
        name='password'
        type='password'
        placeholder='password'
        autoComplete='current-password'
        textAlign='start'
        value={password}
        onChange={setPassword} />
      <Form.Toolbar>
        <Button.Update>Login</Button.Update>
      </Form.Toolbar>
    </Form>
  )
}
