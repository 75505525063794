import Role from '../../models/Role'
import getUrl from '../utils/getUrl'
import { getAuth } from '../authentication'

export default async function (): Promise<Role[]> {
  const url = getUrl('roles')

  const opts = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getAuth()
    }
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`GET licenses returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return json.data.map(
    (item: { attributes: Role }) => item.attributes
  )
}
