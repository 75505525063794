import { useState, useCallback } from 'react'

export function useModalState () {
  const [state, setState] = useState(null)

  const setter = useCallback((args) => {
    setState(args)
  }, [])

  return [state, setter] as const
}
