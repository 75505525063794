import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export interface CreateClient {
  name: string
  email: string
}

export async function createClient (args: CreateClient) {
  const url = getUrl('clients?include=created_by,archived_by')

  const body = JSON.stringify({
    data: {
      type: 'clients',
      attributes: args
    }
  })

  const opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 201) {
    throw new Error(`POST clients returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
