import React from 'react'
import { browserHistory, Router, RedirectFunction, RouterState } from 'react-router'

import routeFactory from './routes'

const checkAuth = (nextState: RouterState, replace: RedirectFunction) => {
  if (localStorage.getItem('accessToken') === null) {
    replace({ pathname: '/login' })
  } else if (nextState.location.pathname === '/') {
    replace({ pathname: '/home' })
  }
}

const redirectIfLoggedIn = (_nextState: RouterState, replace: RedirectFunction) => {
  if (localStorage.getItem('accessToken') !== null) {
    replace({ pathname: '/' })
  }
}

const routes = routeFactory({
  checkAuth,
  redirectIfLoggedIn
})

// Component
function Dispatcher () {
  return (
    <Router history={browserHistory} routes={routes} />
  )
}

// eslint-disable-next-line no-undef
export default Dispatcher
