import { useContext, Dispatch } from 'react'

import { Message, MessagesContext } from './MessagesContext'
import { Context, DispatchAction } from './Context'

type DispatchMessage = Dispatch<DispatchAction<Message | null>>

const dispatchFailure = (dispatch: DispatchMessage) => (value: string) => dispatch({
  type: 'set',
  value: {
    value,
    type: 'failure'
  }
})

const dispatchSuccess = (dispatch: DispatchMessage) => (value: string) => dispatch({
  type: 'set',
  value: {
    value,
    type: 'success'
  }
})

const clearMessage = (dispatch: DispatchMessage) => () => dispatch({ type: 'clear' })

export function useMessages () {
  const ctx: Context<Message | null> = useContext(MessagesContext)
  const { dispatch } = ctx

  return {
    dispatchFailure: dispatchFailure(dispatch),
    dispatchSuccess: dispatchSuccess(dispatch),
    clearMessage: clearMessage(dispatch)
  }
}
