import React from 'react'

import { useModal } from '../../../components/molecules/Modal'
import Client from '../../../models/Client'

export function useDetailsModal () {
  const modal = useModal()

  return (client: Client) => modal.notice({
    title: 'Client Details',
    children: (
      <>
        Id: {client.id} <br />
        Name: {client.name} <br />
        Client name: {client.name}<br />
        Client email: {client.email} <br />
        Created by: {client?.created_by?.username} <br />
      </>
    ),
    onOutsideClick: 'reject'
  })
}
