import React, { useState } from 'react'

import Client from '../../../models/Client'
import { listClients } from '../../../api/clients'
import TablePage from '../../../components/organisms/TablePage'
import { Column } from '../../../components/organisms/Table'
import { useCreateModal } from '../components/useCreateModal'
import { useDetailsModal } from '../components/useDetailsModal'
import { useEditModal, EditResult } from '../components/useEditModal'
import { nullify, useAbortableEffect, useMessages } from '../../../utils'

import './index.css'

const search = (needle: string, haystack: Client[]) => haystack
  .filter(
    client => client.name.toLowerCase().includes(needle.toLowerCase())
    || client.email.toLowerCase().includes(needle.toLowerCase())
  )

const getField = (column?: string) => {
  switch (column) {
    case 'Name':
      return 'name'
    case 'Email':
      return 'email'
  }
  return undefined
}

export default function Clients () {
  const [clientData, setClientData] = useState<Client[]>([])
  const { dispatchFailure } = useMessages()
  const createModal = useCreateModal()
  const editModal = useEditModal()
  const detailsModal = useDetailsModal()

  useAbortableEffect((ifMounted) => {
    listClients()
      .then(result => ifMounted(() => setClientData(result)))
      .catch(e => {
        dispatchFailure('Retrieving Clients failed')
        console.log(e)
      })
  }, [])

  const onCreate = () => {
    createModal()
      .then((client: Client) => {
        setClientData(clients => clients.concat(client))
      }).catch(nullify)
  }

  const onEdit = (client: Client) => {
    editModal(client).then((result: EditResult) => {
      if (result.updated) {
        setClientData(
          clientData.map(mapClient => {
            if (result.updated?.id === mapClient.id) {
              return result.updated
            } else {
              return mapClient
            }
          })
        )
      }

      if (result.deleted) {
        setClientData(clientData.filter(filterClient => filterClient.id !== result.deleted?.id))
      }
    }).catch(nullify)
  }

  const columns: Column<Client>[] = [
    {
      header: 'Name',
      content: (item: Client) => item.name
    },
    {
      header: 'Email',
      content: (item: Client) => item.email
    },
    {
      header: '',
      content: 'edit',
      isButton: true,
      onClick: onEdit
    }
  ]

  return (
    <>
      <TablePage
        description='All clients in the database'
        data={clientData}
        columns={columns}
        getField={getField}
        search={search}
        onCreate={onCreate}
        onItemClick={(client: Client) => detailsModal(client).catch(nullify)} />
    </>
  )
}
