import React from 'react'
import { Button } from '@exivity/ui'

import { Modal } from '../Modal'
import { useModalState } from '../useModalState'

import { ModalVariationProps } from './index'

export function Notice ({
  resolve,
  title = 'Notice',
  children,
  reject,
  ...rest
}: ModalVariationProps) {
  const [toResolve, setToResolve] = useModalState()

  return (
    <Modal
      reject={reject}
      title={title}
      {...rest}
      buttons={
        <Button data-testid='modal-accept' success onClick={() => resolve(toResolve)}>
          ok
        </Button>
      }>
      {
        typeof children === 'function'
          ? children(setToResolve)
          : children
      }
    </Modal>
  )
}
