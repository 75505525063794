import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { Select, Button, Flex, Input } from '@exivity/ui'

import License from '../../../models/License'
import Client from '../../../models/Client'
import { createLicense, CreateLicenseArgs } from '../../../api/licenses'
import { listClients } from '../../../api/clients'
import { useAbortableEffect, useMessages } from '../../../utils'
import { useModal } from '../../../components/molecules/Modal'
import sortData from '../../../utils/sortData'

type Resolve = (license: Partial<CreateLicenseArgs>) => void

interface CreateArgs {
  onResolve: Resolve
  clients: Client[]
}

const clientName = (c: { name: string }) => c.name

function CreateLicense ({ onResolve, clients }: CreateArgs) {
  const [client, setClient] = useState<Client | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [encryptionKey, setEncryptionKey] = useState<string>('')

  useEffect(() => {
    onResolve({
      client_id: client?.id,
      start: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
      end: endDate ? format(endDate, 'yyyy-MM-dd') : undefined,
      encryption_key: encryptionKey
    })
  }, [client, startDate, endDate, encryptionKey])

  return (
    <Flex justifyContent='space-around' direction='column' height={400}>
      <Select
        placeholder='select client'
        value={client}
        data={clients}
        labelAccessor={clientName}
        onChange={setClient} />
      <Flex justifyContent='space-between'>
        <Select.DateCalendar
          inputValue={(startDate && format(startDate, 'MMMM do yyyy')) || undefined}
          placeholder='license is valid from'
          value={startDate}
          onChange={setStartDate} />
        <Select.DateCalendar
          inputValue={(endDate && format(endDate, 'MMMM do yyyy')) || undefined}
          placeholder='license is valid until'
          value={endDate}
          onChange={setEndDate} />
      </Flex>
      <Input
        placeholder='enter encryption key'
        value={encryptionKey}
        textAlign=''
        type='password'
        required
        onChange={setEncryptionKey} />
    </Flex>
  )
}

export function useCreateModal () {
  const modal = useModal()
  const [disabled, setDisabled] = useState(false)
  const [clientsList, setClientsList] = useState<Client[]>([])
  const { dispatchFailure, dispatchSuccess, clearMessage } = useMessages()

  useAbortableEffect((isMounted) => {
    listClients().then(
      clients => isMounted(() => setClientsList(sortData(clients, 'name')))
    ).catch(e => {
      dispatchFailure('Retrieving licenses failed')
      console.log(e)
    })
  }, [])

  return () => modal.custom({
    title: 'Create a new license',
    children: (toResolve: Resolve) => (
      <CreateLicense clients={clientsList} onResolve={toResolve} />
    ),
    onOutsideClick: 'reject',
    buttons: (
      resolve: (license: License) => void, reject,
      { toResolve: license }: { toResolve: Partial<CreateLicenseArgs> }
    ) => (
      <>
        <Button.Cancel key='cancel' onClick={() => reject()} />
        <Button.Create key='create' disabled={disabled} onClick={() => {
          if (!license.client_id || !license.start || !license.end || !license.encryption_key) {
            return
          }

          if (license.start >= license.end) {
            return
          }

          setDisabled(true)
          clearMessage()

          createLicense(license as CreateLicenseArgs).then((license) => {
            dispatchSuccess('License has been created')
            resolve(license)
            setDisabled(false)
          }).catch(e => {
            setDisabled(false)
            dispatchFailure('License creation failed')
            console.log(e)
          })
        }} />
      </>
    )
  })
}
