import en from './en.json'

export type NestedMessages = { [key: string]: string | NestedMessages }

export interface Messages {
  [key: string]: NestedMessages
}

const messages: Messages = {
  ...en
}

export default messages
