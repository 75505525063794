import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export default async function (id: number) {
  const url = getUrl(`users/${id}`)

  const opts = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    }
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`DELETE users/${id} returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
