export default function (path?: string) {
  let origin = process.env.REACT_APP_OSSUS_API_URL

  if (typeof origin === 'undefined') {
    throw new Error('no api url set')
  }

  if (!origin.endsWith('/')) {
    origin += '/'
  }

  if (typeof path === 'undefined') {
    return origin
  } else {
    return origin + path
  }
}
