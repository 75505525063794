import React, { ReactNode, useState, useEffect, useMemo } from 'react'
import { Section } from '@exivity/ui'

import Title from '../../atoms/Title'
import Table, { Column, IDObject } from '../Table'
import TableControls from '../TableControls'

import './index.css'

interface TablePageProps<T extends IDObject> {
  description: string
  data: T[]
  columns: Column<T>[]
  getField: (column?: string) => string | undefined
  search: (needle: string, haystack: T[]) => T[]
  onCreate?: () => void
  onItemClick?: (item: T) => void
  children?: ReactNode
}

export default function TablePage<T extends IDObject> ({
  description,
  data,
  columns,
  getField,
  search,
  onCreate,
  onItemClick,
  children
}: TablePageProps<T>) {
  const [searchTerm, setSearchTerm] = useState('')
  const [pageSize, setPageSize] = useState(25)
  const [pageNum, setPageNum] = useState(1)

  useEffect(() => setPageNum(1), [searchTerm, data, pageSize])

  const searchedData = useMemo(
    () => search(searchTerm, data),
    [searchTerm, data]
  )

  const maxPage = useMemo(
    () => Math.ceil(search(searchTerm, data).length / pageSize),
    [searchTerm, data, pageSize]
  )

  return (
    <>
      <Section>
        <Title description={description} />
        <TableControls
          pageSize={pageSize}
          pageNum={pageNum}
          maxPage={maxPage}
          data={data}
          onPageNumUpdate={setPageNum}
          onPageSizeUpdate={setPageSize}
          onSearchTermUpdate={setSearchTerm}
          onCreate={onCreate} />
        {children}
      </Section>
      <Table
        data={searchedData}
        pageSize={pageSize}
        pageNum={pageNum}
        columns={columns}
        getField={getField}
        onItemClick={onItemClick} />
    </>
  )
}
