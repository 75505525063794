import React, { ReactNode } from 'react'

import { ModalProvider as CustomModalProvider } from '../molecules/Modal/useModal'
import { MessagesProvider } from '../../utils/MessagesContext'

import App from '.'

export default function WithProviders ({ children }: { children: ReactNode }) {
  return (
    <MessagesProvider>
      <CustomModalProvider>
        <App>{children}</App>
      </CustomModalProvider>
    </MessagesProvider>
  )
}
