import React, { ReactNode, useReducer, createContext } from 'react'

import { Context, DispatchAction } from './Context'

type MessageType = 'success' | 'failure'

export interface Message {
  type: MessageType
  value: string
}

// @ts-ignore
export const MessagesContext = createContext<Context<Message | null>>({})

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    (_state: Message | null, action: DispatchAction<Message | null>) => {
      switch (action.type) {
        case 'set':
          if (action.value) {
            return {
              ...action.value
            }
          } else {
            return null
          }
        default:
          return null
      }
    }, null)

  const { Provider } = MessagesContext
  return (
    <Provider value={{ state, dispatch }}>{children}</Provider>
  )
}
