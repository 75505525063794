import React from 'react'
import styled from 'styled-components'
import { fromTheme } from '@exivity/ui'
import { MdDashboard, MdBook, MdTrendingUp, MdBusiness, MdGroup } from 'react-icons/md'

import routes from '../../../config/routes'

import NavItem from './NavItem'

import './index.css'

const StyledSeparator = styled.hr`
  height: 1px;
  border: 0;
  border-top: 1px solid  ${fromTheme(theme => theme.colors.gray)};
  margin: ${fromTheme(theme => theme.global.baseSpacing * 0.5)}em 
  ${fromTheme(theme => theme.global.baseSpacing)}em;
`

export default function Sidebar () {
  return (
    <aside>
      <nav className='navbar'>
        <ul className='navbar_list'>
          <NavItem to={`/${routes.home._meta.url}`} index icon={<MdDashboard />}>
            {routes.home._meta.title}
          </NavItem>
          <StyledSeparator className='navbar_separator' />
          <NavItem to={`/${routes.licenses._meta.url}`} index icon={<MdBook />}>
            {routes.licenses._meta.title}
          </NavItem>
          <NavItem to={`/${routes.usages._meta.url}`} index icon={<MdTrendingUp />}>
            {routes.usages._meta.title}
          </NavItem>
          <NavItem to={`/${routes.clients._meta.url}`} index icon={<MdBusiness />}>
            {routes.clients._meta.title}
          </NavItem>
          <NavItem to={`/${routes.users._meta.url}`} index icon={<MdGroup />}>
            {routes.users._meta.title}
          </NavItem>
        </ul>
      </nav>
    </aside>
  )
}
