import React, { useState, useEffect, useRef } from 'react'
import { Input, Button, Flex } from '@exivity/ui'

import { useMessages } from '../../../utils'
import User from '../../../models/User'
import { nullify } from '../../../utils/general'
import { updateUser, UpdateUserArgs, deleteUser } from '../../../api/users'
import { useDeleteConfirmationModal } from '../components/useDeleteConfirmationModal'
import { useModal } from '../../../components/molecules/Modal'

import RoleSelect from './RoleSelect'

type Resolve = (user: UpdateUserArgs) => void

export interface EditResult {
  updated?: User
  deleted?: User
}

const EditUser = ({ onResolve, base }: {onResolve: Resolve; base: User}) => {
  const [role, setRole] = useState(base.role)
  const [username, setUsername] = useState(base.username)
  const [email, setEmail] = useState(base.email)
  const [password, setPassword] = useState('')
  const [newEncryptionKey, setNewEncryptionKey] = useState('')
  const [ownEncryptionKey, setOwnEncryptionKey] = useState('')

  useEffect(() => {
    onResolve({
      role_id: role?.id,
      username,
      email,
      password,
      encryption_key: newEncryptionKey,
      own_encryption_key: ownEncryptionKey
    })
  }, [role, username, email, password, newEncryptionKey, ownEncryptionKey])

  return (
    <Flex justifyContent='space-around' direction='column' height={300}>
      <Input
        placeholder={base ? base.username : 'username'}
        value={username}
        textAlign=''
        onChange={setUsername} />
      <Input
        placeholder={base ? base.email : 'email'}
        value={email}
        textAlign=''
        onChange={setEmail} />
      <Input
        placeholder='password'
        value={password}
        textAlign=''
        onChange={setPassword} />
      <RoleSelect
        value={role || null}
        onChange={setRole} />
      <Input
        placeholder='new encryption key'
        value={newEncryptionKey}
        textAlign=''
        onChange={setNewEncryptionKey} />
      {newEncryptionKey && (
        <Input
          placeholder='your encryption key'
          value={ownEncryptionKey}
          textAlign=''
          onChange={setOwnEncryptionKey} />
      )}
    </Flex>
  )
}

export function useEditModal () {
  const mounted = useRef(true)
  const modal = useModal()
  const [disabled, setDisabled] = useState(false)
  const { clearMessage, dispatchFailure, dispatchSuccess } = useMessages()
  const DeleteConfirmationModal = useDeleteConfirmationModal()

  useEffect(() => () => {
    mounted.current = false
  }, [])

  return (user: User) => modal.custom({
    title: `Edit the user ${user?.username}`,
    children: (toResolve: Resolve) => (
      <EditUser base={user} onResolve={toResolve} />
    ),
    onOutsideClick: 'reject',
    buttons: (
      resolve: (res: EditResult) => void, reject,
      { toResolve: userArgs }: { toResolve: UpdateUserArgs }
    ) => (
      <>
        <Button.Cancel key='cancel' onClick={() => reject()} />
        <Button.Delete key='delete' disabled={disabled} onClick={() => {
          clearMessage()
          DeleteConfirmationModal(user).then(() => {
            deleteUser(user.id).then(() => {
              dispatchSuccess(`User "${user.username}" has been deleted`)
              resolve({ deleted: user })
            }).catch(e => {
              dispatchFailure(`Deleting user "${user.username}" failed`)
              console.log(e)
            })
          }).catch(nullify)
        }} />
        <Button.Update key='update' disabled={disabled} onClick={() => {
          if (userArgs.encryption_key && !userArgs.own_encryption_key) return

          setDisabled(true)
          updateUser(user.id, {
            ...userArgs,
            password: userArgs.password || undefined,
            encryption_key: userArgs.encryption_key || undefined,
            own_encryption_key: userArgs.own_encryption_key || undefined
          }).then((updated: User) => {
            dispatchSuccess(`User "${updated.username}" has been updated`)
            resolve({ updated })
            setDisabled(false)
          }).catch(e => {
            dispatchFailure(`Updating user "${user.username}" failed`)
            console.log(e)
            setDisabled(false)
          })
        }} />
      </>
    )
  })
}
