import React from 'react'

import './index.css'

function UnsupportedBrowser () {
  return (
    <div
      className={`ex-unsupported-browser 
      uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-text-center`}
      data-uk-height-viewport='offset-top: true; offset-bottom: 20px'>
      <h1><span role='img' aria-label='Thinking Face emoji'>🤔</span></h1>
      <h2>Unsupported browser</h2>
      <p className='uk-text-muted'>
        Unfortunately, we don't support your browser at the moment.<br />
        Please contact your system administrator to obtain a list of currently supported browsers.
      </p>
    </div>
  )
}

export default UnsupportedBrowser
