import React from 'react'

import IconLogo from './IconLogo'
import BrandLogo from './BrandLogo'

type LogoType = 'icon' | 'brand'

interface LogoProps {
  fill?: string
  type?: LogoType
  className?: string
}

export default function Logo ({ fill = '#fff', type = 'icon', className }: LogoProps) {
  return (
    <div className={className}>
      {type === 'icon' ? <IconLogo fill={fill} /> : <BrandLogo fill={fill} />}
    </div>
  )
}
