
const LEVEL_DEBUG = 'debug'
const LEVEL_INFO = 'info'
const LEVEL_WARNING = 'warning'
const LEVEL_ERROR = 'error'
const LEVEL_CRITICAL = 'critical'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logError (error: Error, context: any = {}) {
  self.console && console.error
    && console.error('%c ERROR ', 'background-color: tomato; color: white;', error, context)
}

export const levels = {
  LEVEL_DEBUG,
  LEVEL_INFO,
  LEVEL_WARNING,
  LEVEL_ERROR,
  LEVEL_CRITICAL
}
