import React from 'react'
import { InjectedRouter, withRouter } from 'react-router'
import { Flex, Button } from '@exivity/ui'

import Logo from '../../molecules/Logo'

import PageTitle, { Route } from './PageTitle'
import Messages from './Messages'

import './index.css'

interface LogoutButtonProps {
  onLogout: () => void
}

const LogoutButton = ({ onLogout }: LogoutButtonProps) => localStorage.getItem('accessToken')
  ? (
    <Button.Cancel
      className='logout-button'
      small
      outlined
      onClick={onLogout}>
      logout
    </Button.Cancel>
  ) : null

interface HeaderProps {
  routes: Route[]
  router?: InjectedRouter
}

function Header ({ routes, router }: HeaderProps) {
  const onLogout = () => {
    localStorage.removeItem('accessToken')
    router && router.push('/login')
  }

  return (
    <>
      <Messages />
      <Flex className='header' direction='row' justifyContent='space-between' alignItems='center'>
        <Flex direction='row' width='fit-content'>
          <Logo className='iconlogo' />
          <Logo type='brand' className='brandlogo' />
          <PageTitle routes={routes} />
        </Flex>
        <LogoutButton onLogout={onLogout} />
      </Flex>
    </>
  )
}

export default withRouter<HeaderProps>(Header)
