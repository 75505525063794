import React from 'react'

import { useModal } from '../../../components/molecules/Modal'
import License from '../../../models/License'

export function useArchiveConfirmationModal () {
  const modal = useModal()

  return (license: License) => modal.warning({
    title: `Are you sure you want to ${license.archived ? 'un' : ''}archive this license?`,
    children: (
      <>
        ID: {license?.id}<br />
        Client: {license?.client.name}<br />
        Hash: {license?.hash}
      </>
    ),
    onOutsideClick: 'reject'
  })
}
