
export interface RouteInfo {
  url: string
  title?: string
  permission?: number
  icon?: string
}

export interface Route {
  _meta: RouteInfo
}

const routes = {
  root: { _meta: { url: '/' } },
  login: {
    _meta: {
      url: 'login',
      title: 'Login'
    }
  },
  home: {
    _meta: {
      url: 'home',
      title: 'Dashboard'
    }
  },
  licenses: {
    _meta: {
      url: 'licenses',
      title: 'Licenses'
    }
  },
  usages: {
    _meta: {
      url: 'usages',
      title: 'Usage'
    }
  },
  users: {
    _meta: {
      url: 'users',
      title: 'Users'
    }
  },
  clients: {
    _meta: {
      url: 'clients',
      title: 'Clients'
    }
  },
  notFound: {
    _meta: {
      url: '*',
      title: 'Not found'
    }
  },
  forbidden: {
    _meta: {
      url: 'forbidden',
      title: 'Forbidden'
    }
  }
}

export default routes
