import React, { useState } from 'react'
import { Toggle, Flex } from '@exivity/ui'

import License from '../../../models/License'
import { listLicenses, archive } from '../../../api/licenses'
import TablePage from '../../../components/organisms/TablePage'
import { Column } from '../../../components/organisms/Table'
import { nullify, useAbortableEffect, useMessages } from '../../../utils'
import {
  useCreateModal,
  useArchiveConfirmationModal,
  useDetailsModal,
  isDuplicateHash,
  DuplicateWarning
} from '../components'

import './index.css'

const search = (needle: string, haystack: License[]) => haystack.filter(
  (license) => license.client.name.toLowerCase().includes(needle.toLowerCase())
    || license.hash.toLowerCase().includes(needle.toLowerCase())
)

const getField = (column?: string) => {
  switch (column) {
    case 'Client':
      return 'client.name'
    case 'Valid From':
      return 'start'
    case 'Valid Until':
      return 'end'
    case 'Hash':
      return 'hash'
    case 'Archived':
      return 'archived'
  }
  return undefined
}

function Licenses () {
  const [licenseData, setLicenseData] = useState<License[]>([])
  const [showArchived, setShowArchived] = useState(false)
  const { dispatchFailure, dispatchSuccess } = useMessages()
  const archiveConfirmationModal = useArchiveConfirmationModal()
  const detailsModal = useDetailsModal()
  const createModal = useCreateModal()

  useAbortableEffect((isMounted) => {
    listLicenses()
      .then(result => isMounted(() => setLicenseData(result)))
      .catch(e => {
        dispatchFailure('Retrieving licenses failed')
        console.log(e)
      })
  }, [])

  const onNewLicense = (newLicense: License) => {
    setLicenseData(licenseData.concat(newLicense))
  }

  const onArchive = (license: License) => archiveConfirmationModal(license)
    .then(() => archive(license.id, license.archived).then((archived: License) => {
      setLicenseData(licenseData.map(
        maplicense => {
          if (maplicense.id === archived.id) {
            return archived
          } else {
            return maplicense
          }
        }
      ))
      dispatchSuccess(`License has been ${archived.archived ? '' : 'un'}archived`)
    }).catch(e => {
      dispatchFailure(`License ${license.archived ? 'un' : ''}archiving failed`)
      console.log(e)
    }))
    .catch(nullify)

  const columns: Column<License>[] = [
    {
      header: 'Client',
      content: (item: License) => isDuplicateHash(item.hash, licenseData)
        ? (<DuplicateWarning contentText={item.client.name} />)
        : item.client.name
    },
    {
      header: 'Valid From',
      content: (item: License) => item.start
    },
    {
      header: 'Valid Until',
      content: (item: License) => item.end
    },
    {
      header: 'Hash',
      content: (item: License) => item.hash
    },
    {
      header: 'Archived',
      content: (item: License) => item.archived ? 'yes' : 'no'
    },
    {
      header: '',
      content: 'archive',
      isButton: true,
      onClick: onArchive
    }
  ]

  return (
    <>
      <TablePage
        description='All licenses in the database'
        data={licenseData.filter(l => !l.archived || showArchived)}
        columns={columns}
        getField={getField}
        search={search}
        onCreate={() => createModal().then(onNewLicense).catch(nullify)}
        onItemClick={(license: License) => detailsModal(license).catch(nullify)}>
        <Flex alignItems='center'>
          <Toggle
            small
            className='show-archived-toggle'
            selected={showArchived}
            onClick={() => setShowArchived(!showArchived)}>
            show archived
          </Toggle>
        </Flex>
      </TablePage>
    </>
  )
}

export default Licenses
