import React, { useState, useRef, useEffect } from 'react'
import { Select } from '@exivity/ui'

import Role from '../../../models/Role'
import { listRoles } from '../../../api/roles'

interface RoleSelectProps {
  value: Role | null
  onChange: (role: Role) => void
}

export default function RoleSelect ({ value, onChange }: RoleSelectProps) {
  const mounted = useRef(true)
  const [rolesList, setRolesList] = useState<Role[]>([])

  useEffect(() => () => {
    mounted.current = false
  }, [])

  useEffect(() => {
    listRoles().then(res => mounted.current && setRolesList(res))
  }, [])

  return (
    <Select
      placeholder='select role'
      value={value}
      data={rolesList}
      labelAccessor={(role: Role) => role.role_name}
      onChange={(role: Role) => onChange(role)} />
  )
}
