import React from 'react'

import ClipboardCopy from '../../atoms/ClipboardCopy'

import './index.css'

interface TextDisplayProps {
  copyButton?: boolean
  dark?: boolean
  border?: boolean
  textToCopy?: string
  text: string
}

function TextDisplay ({ copyButton, dark, border, text, textToCopy }: TextDisplayProps) {
  let className = 'text-display'

  if (dark) {
    className += ' dark'
  }

  if (border) {
    className += ' with-border'
  }

  let component = (<span>{text}</span>)

  if (copyButton) {
    component = (<ClipboardCopy textToCopy={textToCopy ?? text}>{component}</ClipboardCopy>)
  }

  component = (<div className={className}>{component}</div>)

  return component
}

export default TextDisplay
