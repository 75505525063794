import React from 'react'

import { Modal } from '../Modal'
import { useModalState } from '../useModalState'

import { ModalVariationProps } from './'

export interface CustomModalProps extends Omit<ModalVariationProps, 'buttons'> {
  buttons: (
    resolve: (props: any) => void,
    reject: (props?: unknown) => void,
    state: {
      toResolve: any
      toReject: any
    }
  ) => React.ReactElement | React.ReactElement[]
}

export function Custom ({
  resolve,
  reject,
  title = 'Please confirm',
  children = 'Would you like to proceed?',
  buttons,
  ...rest
}: CustomModalProps) {
  const [toResolve, setToResolve] = useModalState()
  const [toReject, setToReject] = useModalState()

  return (
    <Modal
      reject={reject}
      title={title}
      {...rest}
      buttons={buttons(
        (toResolve) => resolve(toResolve),
        (toReject) => reject(toReject),
        {
          toResolve,
          toReject
        }
      )}>
      {
        typeof children === 'function'
          ? children(setToResolve, setToReject)
          : children
      }
    </Modal>
  )
}
