import License from '../../models/License'
import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export default async function (id: string, current: boolean): Promise<License> {
  const url = getUrl(`licenses/${id}?include=created_by,archived_by,client`)

  const body = JSON.stringify({
    data: {
      type: 'licenses',
      attributes: {
        archived: !current
      }
    }
  })

  const opts = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`POST licenses returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
