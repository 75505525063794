import React from 'react'

import { useModal } from '../../../components/molecules/Modal'
import TextDisplay from '../../../components/molecules/TextDisplay'
import Table, { Column } from '../../../components/organisms/Table'
import { UsageRecord } from '../utils'

interface UsageAmount {
  id: string
  month: string
  cupr: number
}

const getField = (column?: string) => {
  switch (column) {
    case 'Month':
      return 'month'
    case 'CUPR usage':
      return 'cupr'
  }
  return undefined
}

export function useDetailsModal () {
  const modal = useModal()

  const columns: Column<UsageAmount>[] = [
    {
      header: 'Month',
      content: (item: UsageAmount) => item.month
    },
    {
      header: 'CUPR Usage',
      content: (item: UsageAmount) => item.cupr.toString()
    }
  ]

  return (record: UsageRecord) => modal.notice({
    title: 'Usage Details',
    children: (
      <>
        Client: {record.clientName}
        <br />
        License Hash:
        <br />
        <TextDisplay dark border copyButton text={record.license.hash} />
        <br /><br />
        <Table
          pageNum={1}
          pageSize={50}
          columns={columns}
          getField={getField}
          data={record.records.map(usage => ({ ...usage, id: usage.month }))} />
      </>
    ),
    onOutsideClick: 'reject'
  })
}
