import React from 'react'
import { ThemeProvider } from '@exivity/ui'

import { MessagesProvider } from '../../../../utils/MessagesContext'
import { Route } from '../../../../components/organisms/Header/PageTitle'
import StyledMain from '../../../../components/molecules/StyledMain'
import LoginLogic from '../../components/LoginLogic'

import './index.css'

interface LoginProps {
  routes: Route[]
}

function Login ({ routes }: LoginProps) {
  return (
    <ThemeProvider>
      <MessagesProvider>
        <StyledMain>
          <LoginLogic routes={routes} />
        </StyledMain>
      </MessagesProvider>
    </ThemeProvider>
  )
}

export default Login
