import { useEffect, useRef } from 'react'

export function useAbortableEffect<T extends (...args: any) => any> (effect: T, deps: any[]) {
  const isMounted = useRef(true)

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  useEffect(() => {
    function ifMounted<F extends (...args: any) => any> (fn: F) {
      if (isMounted.current) {
        fn()
      }
    }

    const cleanup = effect(ifMounted)

    return () => {
      if (typeof cleanup === 'function') {
        cleanup()
      }
    }
  }, deps)
}
