import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export interface UpdateClient {
  name?: string
  email?: string
}

export async function updateClient (id: number, args: UpdateClient) {
  const url = getUrl(`clients/${id}?include=created_by,archived_by`)

  const body = JSON.stringify({
    data: {
      type: 'clients',
      attributes: args
    }
  })

  const opts = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`POST clients/${id} returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
