import React from 'react'

import './index.css'

function NotFound () {
  return (
    <div className='ex-404 uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-text-center'
      data-uk-height-viewport='offset-top: true; offset-bottom: 20px'>
      <h1>404</h1>
      <p className='uk-text-muted'>
        This resource could not be found.<br />
        Please navigate back or contact support.
      </p>
    </div>
  )
}

export default NotFound
