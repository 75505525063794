import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export interface UpdateUserArgs {
  username?: string
  email?: string
  password?: string
  role_id?: number
  encryption_key?: string
  own_encryption_key?: string
}

export async function updateUser (id: number, args: UpdateUserArgs) {
  const url = getUrl(`users/${id}?include=created_by,archived_by,role`)
  const { own_encryption_key: encryption_key, ...attributes } = args

  const body = JSON.stringify({
    data: {
      type: 'users',
      attributes,
      metadata: {
        encryption_key
      }
    }
  })

  const opts = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`POST users/${id} returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
