import React, { useRef, useEffect, useContext } from 'react'
import { Heading } from '@exivity/ui'

import { MessagesContext, Message } from '../../../utils/MessagesContext'
import { Context } from '../../../utils/Context'

export default function Messages () {
  const mounted = useRef(true)
  const message: Context<Message | null> = useContext(MessagesContext)
  const { dispatch } = message

  useEffect(() => () => {
    mounted.current = false
  }, [])

  if (message.state === null) {
    return null
  }

  setTimeout(() => {
    mounted.current && dispatch({ type: 'clear' })
  }, 3000)

  return (
    <Heading
      className={`message message__${message.state.type}`}
      onClick={() => dispatch({ type: 'clear' })}>
      {message.state.value}
    </Heading>
  )
}
