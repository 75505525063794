import React from 'react'

import './index.css'

function Forbidden () {
  return (
    <div className='ex-403 uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-text-center'
      data-uk-height-viewport='offset-top: true; offset-bottom: 20px'>
      <h1>403</h1>
      <p className='uk-text-muted'>
        Access to this resource is forbidden.<br />
        Please navigate back or contact your administrator.
      </p>
    </div>
  )
}

export default Forbidden
