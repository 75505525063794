import React, { useRef, useEffect } from 'react'
import { InjectedRouter, withRouter } from 'react-router'
import { Flex } from '@exivity/ui'

import { useMessages } from '../../../../utils/useMessages'
import Header from '../../../../components/organisms/Header'
import { Route } from '../../../../components/organisms/Header/PageTitle'
import LoginForm from '../LoginForm'
import { authenticate } from '../../../../api/authentication'

interface LoginProps {
  routes: Route[]
  router?: InjectedRouter
}

function LoginLogic ({ routes, router }: LoginProps) {
  const mounted = useRef(true)
  const { dispatchFailure } = useMessages()

  useEffect(() => () => {
    mounted.current = false
  }, [])

  const attemptLogin = async (email: string, password: string) => {
    let token = ''
    try {
      token = await authenticate(email, password)
    } catch (err) {
      console.error(err)
      if (mounted.current) {
        dispatchFailure('Login failed')
      }
      return
    }

    if (mounted.current) {
      localStorage.setItem('accessToken', token)
      router && router.push('/home')
    }
  }

  return (
    <>
      <Header routes={routes} />
      <Flex direction='row' justifyContent='center'>
        <LoginForm onSubmit={attemptLogin} />
      </Flex>
    </>
  )
}

export default withRouter<LoginProps>(LoginLogic)
