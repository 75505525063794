import getUrl from '../utils/getUrl'

declare global {
  interface Window { Exivity: { Auth?: string } }
}

export default async function (email: string, password: string) {
  if (window.Exivity && window.Exivity.Auth) {
    return
  }

  const url = getUrl('authentication')

  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      strategy: 'local',
      email,
      password
    })
  })

  if (resp.status !== 201) {
    throw new Error(`POST authentication returned ${resp.status}:\n${await resp.text()}`)
  }

  if (!window.Exivity) {
    window.Exivity = {}
  }

  return (await resp.json()).accessToken
}
