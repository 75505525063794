import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export interface CreateUserArgs {
  username: string
  email: string
  password: string
  role_id: number
  encryption_key: string
  own_encryption_key: string
}

export async function createUser (args: CreateUserArgs) {
  const url = getUrl('users?include=created_by,archived_by,role')
  const { own_encryption_key: encryption_key, ...attributes } = args

  const body = JSON.stringify({
    data: {
      type: 'users',
      attributes,
      metadata: {
        encryption_key
      }
    }
  })

  const opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 201) {
    throw new Error(`POST users returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
