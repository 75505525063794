import React, { ReactNode, useMemo, useState } from 'react'
import { Table as UITable, Button } from '@exivity/ui'

import { sortData } from '../../../utils'

import './index.css'

export interface IDObject {
  id: string | number
}

type ColumnContent<T extends IDObject> = string | ((item: T) => number | string) | ReactNode

export interface Column<T extends IDObject> {
  header: string
  content: ColumnContent<T>
  isButton?: boolean
  onClick?: (item: T) => void
}

interface ColumnWithContentCell<T extends IDObject>
  extends Pick<Column<T>, Exclude<keyof Column<T>, 'content'>>
{
  content: ({ item }: { item: T }) => JSX.Element
}

interface SortObj {
  column: string
  desc: boolean
}

const setOrder = (column: string, existing?: SortObj): SortObj => {
  if (existing?.column === column) {
    return {
      ...existing,
      desc: !existing.desc
    }
  }

  return {
    column,
    desc: false
  }
}

interface TableArguments<T extends IDObject> {
  data: T[]
  columns: Column<T>[]
  pageSize: number
  pageNum: number
  getField: (column?: string) => string | undefined
  onItemClick?: (item: T) => void
}

const headerCell = (header: string, onClick: (key: SortObj) => void, sortedOn?: SortObj) => (
  <div
    className={`table-header ${sortedOn?.column === header ? 'sorted' : ''}`}
    onClick={() => onClick(setOrder(header, sortedOn))}>
    { header }
  </div>
)

export default function Table<T extends IDObject> ({
  data,
  columns,
  pageSize,
  pageNum,
  getField,
  onItemClick
}: TableArguments<T>) {
  const [sortedOn, setSortedOn] = useState<SortObj>()

  const pageData = useMemo(
    () => sortData(data, getField(sortedOn?.column), sortedOn?.desc)
      .slice(pageSize * (pageNum - 1), pageSize * pageNum),
    [data, pageSize, pageNum, sortedOn]
  )

  const tableCell = (item: T, content: ColumnContent<T>, onClick?: (item: T) => void) => (
    <div className='table-cell' onClick={() => onClick?.(item)}>
      { typeof content === 'function' ? content(item) : content }
    </div>
  )

  const tableButton = (item: T, text: ColumnContent<T>, onClick?: (item: T) => void) => (
    <Button
      tiny
      danger
      filledOutline
      className='edit-button'
      onClick={() => onClick?.(item)}>
      { typeof text === 'function' ? text(item) : text }
    </Button>
  )

  const wrappedColumns = columns.map((column: Column<T>) => ({
    ...column,
    content: column.isButton
      ? ({ item }: { item: T }) => tableButton(
        item,
        column.content,
        column.onClick
      )
      : ({ item }: { item: T }) => tableCell(
        item,
        column.content,
        onItemClick
      )
  })).map((column: ColumnWithContentCell<T>) => ({
    ...column,
    width: column.isButton
      ? '5%'
      : `${Math.floor(95 / (columns.filter(cs => !cs.isButton).length || 1))}%`,
    header: () => headerCell(
      column.header, setSortedOn, sortedOn
    )
  }))

  return (
    <div className='table'>
      <UITable.Virtual
        keyExtractor={(item: T) => item.id}
        data={pageData}
        columns={wrappedColumns} />
    </div>
  )
}
