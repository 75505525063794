import { detect } from 'detect-browser'

type SupportedBrowsers = {
  [index: string]: number | boolean
}

const supportedBrowsers: SupportedBrowsers = {
  chrome: 59,
  edge: 16,
  opera: 46,
  firefox: 65,
  'edge-chromium': 80,

  // We can't support safari because:
  // - Not tested
  safari: false

  // Let's not even talk about IE
}

export const isSupportedBrowser = () => {
  const browser = detect()

  if (!browser) {
    return false
  }

  if (!supportedBrowsers[browser.name]) {
    return false
  }

  if (!browser.version) {
    return false
  }

  return parseFloat(browser.version) >= supportedBrowsers[browser.name]
}
