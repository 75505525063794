import { MdContentCopy } from 'react-icons/md'
import React, { ReactNode, useState } from 'react'

import './index.css'

interface ClipboardCopyProps {
  textToCopy: string
  children?: ReactNode
}

function ClipboardCopy ({ textToCopy, children }: ClipboardCopyProps) {
  const [showCopied, setCopied] = useState(false)

  return (
    <div className='clipboard-container'>
      {children ?? textToCopy}
      <MdContentCopy
        className='clipboard-button'
        onClick={() => {
          navigator.clipboard.writeText(textToCopy)
          setCopied(true)
          setTimeout(() => setCopied(false), 1500)
        }} />
      {
        showCopied && <div className='clipboard-copied-message'>Copied!</div>
      }
    </div>
  )
}

export default ClipboardCopy
