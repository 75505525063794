import React, { useState, useEffect } from 'react'
import { Input, Button, Flex } from '@exivity/ui'

import { useModal } from '../../../components/molecules/Modal'
import { useMessages } from '../../../utils'
import { createClient, CreateClient as ClientArgs } from '../../../api/clients'
import Client from '../../../models/Client'

export type Resolve = (client: ClientArgs) => void

function CreateClient ({ onResolve }: { onResolve: Resolve }) {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    onResolve({ name, email })
  }, [name, email])

  return (
    <Flex justifyContent='space-around' direction='column' height={300}>
      <Input
        placeholder='username'
        value={name}
        textAlign=''
        required
        onChange={setName} />
      <Input
        placeholder='email'
        value={email}
        textAlign=''
        required
        onChange={setEmail} />
    </Flex>
  )
}

export function useCreateModal () {
  const modal = useModal()
  const [disabled, setDisabled] = useState(false)
  const { dispatchSuccess, dispatchFailure, clearMessage } = useMessages()

  return () => modal.custom({
    title: 'Create a new Client',
    children: (toResolve: Resolve) => <CreateClient onResolve={toResolve} />,
    buttons: (
      resolve: (client: Client) => void, reject, { toResolve: client }: { toResolve: ClientArgs }
    ) => (
      <>
        <Button.Cancel disabled={disabled} onClick={reject} />
        <Button.Create disabled={disabled} onClick={() => {
          setDisabled(true)
          clearMessage()

          createClient(client)
            .then((client) => {
              dispatchSuccess('Client has been created')
              resolve(client)
              setDisabled(false)
            })
            .catch(e => {
              setDisabled(false)
              dispatchFailure('Client creation failed')
              console.log(e)
            })
        }} />
      </>
    ),
    onOutsideClick: 'reject'
  })
}
