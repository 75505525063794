import React from 'react'
import { ThemeProvider, Grid } from '@exivity/ui'

import StyledMain from '../molecules/StyledMain'
import SideBar from '../organisms/Sidebar'
import Header from '../organisms/Header'

import './app.css'

const gridTemplate = `
  "header header" 60px
  "sidebar main" 1fr / 196px 1fr
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function App (props: any) {
  return (
    <ThemeProvider>
      <StyledMain>
        <Grid template={gridTemplate} height='100%'>
          <Grid.Item area='header'>
            <Header routes={props.routes} />
          </Grid.Item>
          <Grid.Item area='sidebar' className='sidebar'>
            <SideBar />
          </Grid.Item>
          <Grid.Item area='main' padding='10px'>
            {props.children}
          </Grid.Item>
        </Grid>
      </StyledMain>
    </ThemeProvider>
  )
}

export default App
