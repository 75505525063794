import { mapValues } from 'lodash'

import * as auth from './auth'
import * as licenses from './licenses'
import * as clients from './clients'
import * as users from './users'
import * as usages from './usages'

const allDomains = { auth, licenses, clients, users, usages }

type DomainNames = keyof typeof allDomains
enum Parts {
  pages = 'pages',
  components = 'components'
}

export type Domain = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Parts.pages]?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Parts.components]?: any
}

type AllDomains = { [domainName in DomainNames]: Domain }

function combine<P extends Parts> (part: Parts, domains: AllDomains) {
  return mapValues<AllDomains, Domain[P]>(domains, (domain) => domain[part] || {})
}

export const pages = combine<Parts.pages>(Parts.pages, allDomains)
export const components = combine<Parts.components>(Parts.components, allDomains)
