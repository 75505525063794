const mergeRecords = (
  left: Record<string, string>,
  right: Record<string, string>,
  baseKey: string
) => {
  const res = { ...left }

  for (const key of Object.keys(right)) {
    const fieldKey = (baseKey + '.' + key).replace(/\.+$/, '')
    res[fieldKey] = right[key]
  }

  return res
}

const mergeArrays = (
  left: Record<string, string>[],
  right: Record<string, string>[],
  baseKey: string
) => left.flatMap(l => right.map(r => mergeRecords(l, r, baseKey)))

export const flattenToStringRecord = (rawData: unknown): Record<string, string>[] => {
  if (rawData && Array.isArray(rawData)) {
    return rawData.flatMap(flattenToStringRecord)
  } else if (!rawData || typeof rawData !== 'object') {
    return [{ '': `${rawData ?? null}` }]
  } else if (typeof rawData === 'function') {
    throw new Error('Can\'t flatten function to Record<string, string>')
  }

  const data = rawData as Record<string, unknown>

  let res: Record<string, string>[] = [{}]

  for (const key of Object.keys(data)) {
    res = mergeArrays(
      res,
      flattenToStringRecord(data[key]),
      key
    )
  }

  return res
}
