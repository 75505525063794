import React, { createContext, useContext, useState, useCallback, useRef, useMemo } from 'react'

import { Warning, Confirm, Notice, Custom, CustomModalProps } from './variations'
import type { ModalProps } from './Modal'

const modals = {
  confirm: Confirm,
  notice: Notice,
  warning: Warning,
  custom: Custom
}

interface ModalContextShape {
  showModal: (
    { type, args }: { type: keyof typeof modals; args: Partial<ModalProps|CustomModalProps> },
    resolve: (result?: any) => void,
    reject: () => void
  ) => void
}

const ModalContext = createContext<ModalContextShape|null>(null)

export type { ModalProps }
export type Modal = (props: Partial<ModalProps>) => Promise<void>
export type CustomModal = (props: Partial<CustomModalProps>) => Promise<void>

export interface Modals {
  confirm: Modal
  warning: Modal
  notice: Modal
  custom: CustomModal
}

export function useModal () {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('Modal can be used only within its root')
  }

  // eslint-disable-next-line max-len
  const makeModal = useCallback((type: keyof typeof modals) => function (args: Partial<ModalProps|CustomModalProps> = {}) {
    return new Promise((resolve: (result?: any) => void, reject) => {
      context.showModal({ type, args }, resolve, reject)
    })
  }, [context])

  return {
    confirm: makeModal('confirm'),
    notice: makeModal('notice'),
    warning: makeModal('warning'),
    custom: makeModal('custom')
  }
}

interface ModalProviderProps {
  children?: React.ReactNode | React.ReactNode[]
}

export function ModalProvider ({ children }: ModalProviderProps) {
  const modalArgs = useRef<any>({})
  const [modalType, setModalType] = useState<keyof Modals | null>(null)

  const showModal = useCallback(({ type, args }, resolve, reject) => {
    modalArgs.current = {
      ...args,
      resolve: <T extends Record<string, unknown>> (args: T) => {
        setModalType(null)
        resolve(args)
      },
      reject: <T extends Record<string, unknown>> (args: T) => {
        setModalType(null)
        reject(args)
      }
    }

    setModalType(type)
  }, [])

  const Modal = modalType && modals[modalType]

  const state = useMemo(() => ({ showModal }), [])

  return (
    <ModalContext.Provider value={state}>
      {children}
      {Modal && <Modal {...modalArgs.current} />}
    </ModalContext.Provider>
  )
}
