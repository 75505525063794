import Usage from '../../../models/Usage'

export interface UsageRecord {
  id: string
  license: {
    id: string
    hash: string
    start: string
    archived: boolean
  }
  clientName: string
  records: {
    cupr: number
    month: string
  }[]
  recentCupr: number
}

function getMostRecentCupr (record: UsageRecord): number {
  if (record.records.length === 1) return record.records[0].cupr

  record.records.sort((a, b) => {
    if (a.month > b.month) return -1
    return 1
  })

  return record.records[0].cupr
}

export function makeUsageRecords (usages: Usage[]): UsageRecord[] {
  const records: { [key: string]: UsageRecord } = {}

  for (const usage of usages) {
    if (typeof records[usage.license.id] === 'undefined') {
      records[usage.license.id] = {
        id: usage.id,
        license: {
          id: usage.license.id,
          hash: usage.license.hash,
          start: usage.license.start,
          archived: usage.license.archived
        },
        clientName: usage.license.client.name,
        records: [{
          cupr: usage.cupr,
          month: usage.month
        }],
        recentCupr: usage.cupr
      }
    } else {
      records[usage.license.id].records.push({
        cupr: usage.cupr,
        month: usage.month
      })
    }
  }

  return Object
    .values(records)
    .map(record => ({ ...record, recentCupr: getMostRecentCupr(record) }))
}
