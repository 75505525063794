import React, { useState, useEffect } from 'react'
import { Input, Button, Flex } from '@exivity/ui'

import { useMessages } from '../../../utils'
import { nullify } from '../../../utils/general'
import Client from '../../../models/Client'
import { deleteClient, updateClient } from '../../../api/clients'
import { useModal } from '../../../components/molecules/Modal'

import { Resolve } from './useCreateModal'

export interface EditResult {
  updated?: Client
  deleted?: Client
}

function EditClient ({ client, onResolve }: { client: Client; onResolve: Resolve }) {
  const [name, setName] = useState(client.name)
  const [email, setEmail] = useState(client.email)

  useEffect(() => {
    onResolve({ name, email })
  }, [name, email])

  return (
    <Flex justifyContent='space-around' direction='column' height={300}>
      <Input
        placeholder={client ? client.name : 'name'}
        value={name}
        textAlign=''
        required
        onChange={setName} />
      <Input
        placeholder={client ? client.email : 'email'}
        value={email}
        textAlign=''
        required
        onChange={setEmail} />
    </Flex>
  )
}

function useDeleteModal () {
  const modal = useModal()

  return (client: Client) => modal.warning({
    title: 'Are you sure you want to delete this client?',
    children: (
      <>
        ID: {client.id}<br />
        Name: {client.name}<br />
        Email: {client.email}
      </>
    ),
    onOutsideClick: 'reject'
  })
}

export function useEditModal () {
  const modal = useModal()
  const confirmDelete = useDeleteModal()

  const { dispatchSuccess, dispatchFailure } = useMessages()

  return (client: Client) => modal.custom({
    title: `Edit ${client.name}`,
    children: (toResolve: Resolve) => <EditClient client={client} onResolve={toResolve} />,
    buttons: (
      resolve: (res: EditResult) => void, reject, { toResolve: _client }: { toResolve: Client }
    ) => (
      <>
        <Button.Cancel onClick={reject} />
        <Button.Delete onClick={() => {
          confirmDelete(client).then(() => {
            deleteClient(client.id)
              .then(() => {
                dispatchSuccess(`${client?.name} has been deleted`)
                resolve({ deleted: client })
              }).catch((e) => {
                dispatchFailure(`Deleting ${client?.name} failed`)
                console.log(e)
              })
          }).catch(nullify)
        }} />
        <Button.Update onClick={() => {
          updateClient(client.id, {
            name: client.name !== _client.name ? _client.name : undefined,
            email: client.email !== _client.email ? _client.email : undefined
          })
            .then((updated: Client) => {
              dispatchSuccess('Client has been updated')
              resolve({ updated })
            })
            .catch(e => {
              dispatchFailure('Updating Client failed')
              console.log(e)
            })
        }} />
      </>
    ),
    onOutsideClick: 'reject'
  })
}
