import React from 'react'
import { Button } from '@exivity/ui'

import { flattenToStringRecord } from './flatten'

type ExportType = 'json' | 'csv'

const download = (filename: string, content: string, type: ExportType) => {
  const fileType = type === 'json' ? 'application/json;charset=utf-8' : 'text/csv;charset=utf-8'

  const blob = new Blob([content], { type: fileType })

  const element = document.createElement('a')
  element.setAttribute('download', filename)
  element.href = URL.createObjectURL(blob)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}

const convertToCSV = (rawData: any[]) => {
  if (rawData.length === 0) return ''

  const data = rawData.flatMap(flattenToStringRecord)
  const keys = Object.keys(data[0])
  const toRemove: string[] = []

  const res = data.map(
    d => Object.keys(d).map(
      k => `${d[k]}`
    ).join(',')
  )

  return [
    keys.filter(k => !toRemove.includes(k)).join(',')
  ].concat(res).join('\n')
}

const exportData = (data: any[], type: ExportType) => {
  if (type === 'json') {
    download('export.json', JSON.stringify(data), type)
  } else if (type === 'csv') {
    download('export.csv', convertToCSV(data), type)
  }
}

interface ExportGroupProps {
  data: any[]
}

export default function ExportGroup ({ data }: ExportGroupProps) {
  return (
    <Button.Group>
      <Button filledOutline small onClick={() => exportData(data, 'json')}>JSON</Button>
      <Button filledOutline small onClick={() => exportData(data, 'csv')}>CSV</Button>
    </Button.Group>
  )
}
