
const convertBoolean = (bool: boolean) => bool ? 'yes' : 'no'
const splitPath = (path: string) => path.split('.')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getField = (path: string, obj: any) => splitPath(path).reduce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (acc?: any, key?: string) => typeof acc === 'object' && key ? acc?.[key] : acc, obj
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function sortData<T extends Record<string, any>> (
  data: T[], key?: string, desc = false
): T[] {
  if (!key) return data

  const returnData = [...data]

  returnData.sort((x, y) => {
    let fieldx = getField(key, x)
    let fieldy = getField(key, y)

    fieldx = typeof fieldx === 'string' ? fieldx.toLowerCase() : fieldx
    fieldy = typeof fieldy === 'string' ? fieldy.toLowerCase() : fieldy

    fieldx = typeof fieldx === 'boolean' ? convertBoolean(fieldx) : fieldx
    fieldy = typeof fieldy === 'boolean' ? convertBoolean(fieldy) : fieldy

    if (fieldx < fieldy) {
      return desc ? 1 : -1
    } else if (fieldx > fieldy) {
      return desc ? -1 : 1
    }
    return 0
  })

  return returnData
}
