import React from 'react'

import { useModal } from '../../../components/molecules/Modal'
import License from '../../../models/License'
import TextDisplay from '../../../components/molecules/TextDisplay'

export function useDetailsModal () {
  const modal = useModal()

  return (license: License) => modal.notice({
    title: 'License Details',
    children: (
      <>
        Client: {license.client.name}<br />
        Valid From: {license.start}<br />
        Valid Until: {license.end}<br /><br />
        Hash:
        <TextDisplay dark border copyButton text={license.hash} />
        <br />
        Key:
        <TextDisplay dark border copyButton text={license.content} />
      </>
    ),
    onOutsideClick: 'reject'
  })
}
