import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export interface CreateLicenseArgs {
  client_id: number
  start: string
  end: string
  encryption_key: string
}

export async function createLicense (args: CreateLicenseArgs) {
  const url = getUrl('licenses?include=created_by,archived_by,client')
  const { encryption_key, ...attributes } = args

  const body = JSON.stringify({
    data: {
      type: 'licenses',
      attributes,
      metadata: {
        encryption_key
      }
    }
  })

  const opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth()
    },
    body
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 201) {
    throw new Error(`POST licenses returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return combineIncluded(json.data, json.included)
}
