import React from 'react'
import { render } from 'react-dom'
import 'typeface-fira-sans'

import Dispatcher from './components/App/Dispatcher'
import UnsupportedBrowser from './components/pages/UnsupportedBrowser'
import { isSupportedBrowser } from './utils'

const root = document.getElementById('root')

const app = isSupportedBrowser() ? <Dispatcher /> : <UnsupportedBrowser />
render(app, root)
