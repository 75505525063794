import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'
import Usage from '../../models/Usage'

export default async function (): Promise<Usage[]> {
  const url = getUrl('usages?include=license.client')

  const opts = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getAuth()
    }
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`GET usages returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  const res = json.data.map(
    (item: { attributes: Usage }) => combineIncluded(item, json.included)
  )

  return res
}
