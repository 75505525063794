import React from 'react'

interface TitleProps {
  description: string
}

function Title ({ description }: TitleProps) {
  return (
    <div className='title-bar'>
      <h3>{description}</h3>
    </div>
  )
}

export default Title
