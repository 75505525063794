
const getRelationship = (item: any, key: string) => item.relationships[key].data

export default function (item: any, included?: any[]) {
  const res = { ...item.attributes }

  if (typeof included !== 'undefined') {
    included.forEach(i => {
      const rel = getRelationship(item, i.type)
      if (rel && rel.id && rel.id === i.id) {
        res[i.type] = i.attributes
      }
    })
  }

  return res
}
