import React from 'react'

export interface Route {
  path: string
  title: string
  url: string
}

interface TitleProps {
  routes: Route[]
}

export default function PageTitle ({ routes }: TitleProps) {
  const title = routes[routes.length - 1].title

  return (
    <h2 className='pagetitle'>
      {title}
    </h2>
  )
}
