import { RouteConfig, EnterHook } from 'react-router'

import routes, { Route } from '../../config/routes'
import { pages } from '../../domains'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import Forbidden from '../pages/Forbidden'

import AppWithProviders from './WithProviders'

interface RouteFactoryProps {
  checkAuth: EnterHook
  redirectIfLoggedIn: EnterHook
}

const routeFactory = ({
  checkAuth,
  redirectIfLoggedIn
}: RouteFactoryProps): RouteConfig => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createRoute = (route: Route, component: any, suffix = '') => ({
    path: `${route._meta.url}${suffix}`,
    component,
    // onEnter: requirePermission, TODO: check permission if needed
    ...route._meta
  })

  return [
    {
      ...createRoute(routes.login, pages.auth.login),
      onEnter: redirectIfLoggedIn
    },
    {
      ...createRoute(routes.root, AppWithProviders),
      onEnter: checkAuth,
      childRoutes: [
        {
          ...createRoute(routes.licenses, pages.licenses)
        },
        {
          ...createRoute(routes.usages, pages.usages)
        },
        {
          ...createRoute(routes.clients, pages.clients)
        },
        {
          ...createRoute(routes.users, pages.users)
        },
        createRoute(routes.home, Home),
        createRoute(routes.forbidden, Forbidden),
        createRoute(routes.notFound, NotFound)
      ]
    }
  ]
}

export default routeFactory
