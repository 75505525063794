import React from 'react'
import { Button } from '@exivity/ui'

import { Modal } from '../Modal'
import { useModalState } from '../useModalState'

import { ModalVariationProps } from './'

export function Confirm ({
  resolve,
  reject,
  title = 'Please confirm',
  children = 'Would you like to proceed?',
  ...rest
}: ModalVariationProps) {
  const [toResolve, setToResolve] = useModalState()
  const [toReject, setToReject] = useModalState()

  return (
    <Modal
      title={title}
      reject={reject}
      {...rest}
      buttons={
        <>
          <Button secondary onClick={() => reject(toReject)}>
            cancel
          </Button>
          <Button data-testid='modal-accept' success onClick={() => resolve(toResolve)}>
            ok
          </Button>
        </>
      }>
      {
        typeof children === 'function'
          ? children(setToResolve, setToReject)
          : children
      }
    </Modal>
  )
}
