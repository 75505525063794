import { MdWarning } from 'react-icons/md'
import React, { useState } from 'react'

import License from '../../../models/License'

export const isDuplicateHash = (hash: string, licenses: License[]) => {
  let found = 0

  for (const license of licenses) {
    if (license.hash === hash) {
      found++

      if (found > 1) {
        return true
      }
    }
  }

  return false
}

interface duplicateWarningProps {
  contentText: string
}

export function DuplicateWarning ({ contentText }: duplicateWarningProps) {
  const [warningIsShown, setWarningIsShown] = useState(false)

  return (
    <div className='warning-container'>
      <MdWarning
        className='warning-icon'
        onMouseEnter={() => setWarningIsShown(true)}
        onMouseLeave={() => setWarningIsShown(false)} />
      {warningIsShown && (
        <div className='duplicate-warning'>
          This license is a duplicate of another license!
        </div>
      )}
      {contentText}
    </div>
  )
}
