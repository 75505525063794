import React, { useState } from 'react'
import { Toggle, Flex } from '@exivity/ui'

import { listUsages } from '../../../api/usages'
import TablePage from '../../../components/organisms/TablePage'
import { Column } from '../../../components/organisms/Table'
import { nullify, useAbortableEffect, useMessages } from '../../../utils'
import { makeUsageRecords, UsageRecord } from '../utils'
import { useDetailsModal } from '../components'

import './index.css'

const search = (needle: string, haystack: UsageRecord[]) => haystack.filter(
  (usage) => usage.clientName.toLowerCase().includes(needle.toLowerCase())
    || usage.license.hash.toLowerCase().includes(needle.toLowerCase())
)

const getField = (column?: string) => {
  switch (column) {
    case 'Client':
      return 'license.client.name'
    case 'Month':
      return 'month'
    case 'CUPR usage':
      return 'cupr'
    case 'License Hash':
      return 'license.hash'
    case 'Archived':
      return 'license.archived'
  }
  return undefined
}

function Usages () {
  const [UsageData, setUsageData] = useState<UsageRecord[]>([])
  const [showArchived, setShowArchived] = useState(false)
  const { dispatchFailure } = useMessages()
  const detailsModal = useDetailsModal()

  useAbortableEffect((isMounted) => {
    listUsages()
      .then(result => isMounted(() => setUsageData(makeUsageRecords(result))))
      .catch(e => {
        dispatchFailure('Retrieving usages failed')
        console.log(e)
      })
  }, [])

  const columns: Column<UsageRecord>[] = [
    {
      header: 'Client',
      content: (item: UsageRecord) => item.clientName
    },
    {
      header: 'CUPR Usage',
      content: (item: UsageRecord) => item.recentCupr.toString()
    },
    {
      header: 'License Hash',
      content: (item: UsageRecord) => item.license.hash
    },
    {
      header: 'Archived',
      content: (item: UsageRecord) => item.license.archived ? 'yes' : 'no'
    }
  ]

  return (
    <>
      <TablePage
        description='Usage data of the licenses'
        data={UsageData.filter(
          u => (!u.license.archived && !showArchived) || (showArchived && u.license.archived)
        )}
        columns={columns}
        getField={getField}
        search={search}
        onItemClick={(usage: UsageRecord) => detailsModal(usage).catch(nullify)}>
        <Flex alignItems='center'>
          <Toggle
            small
            className='show-archived-toggle'
            selected={showArchived}
            onClick={() => setShowArchived(!showArchived)}>
            show archived
          </Toggle>
        </Flex>
      </TablePage>
    </>
  )
}

export default Usages
