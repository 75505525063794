import React, { ReactElement, ReactNode } from 'react'
import { Overlay, Heading } from '@exivity/ui'

import { ModalWrapper, Header, Body, Footer } from './ModalElements'

export interface ModalProps {
  /**
   * @description The message, warning, confirmation message or content that the modal displays.
   */
  children: ReactNode
  /**
   * @description The title that is displayed in the modal
   */
  title?: ReactNode
  /**
   * @description A button or an array of buttons that serve as user responses to the modal.
   * E.g. Agree, Continue or Cancel
   */
  buttons?: ReactElement | ReactElement[]
  /**
   * @description Event listener that fires when the user clicks somewhere outside the modal.
   * A signifier that the user wants the modal to disappear.
   */
  onOutsideClick?: (() => void) | 'reject'
  reject: (reason?: unknown) => void
}

export function Modal ({ title, children, buttons, onOutsideClick, reject, ...rest }: ModalProps) {
  const onOverlayClick = React.useCallback((e) => {
    if (onOutsideClick === 'reject' && e.currentTarget === e.target) {
      reject()
    } else if (typeof onOutsideClick === 'function' && e.currentTarget === e.target) {
      onOutsideClick()
    }
  }, [onOutsideClick])

  return (
    <Overlay {...rest} onClick={onOverlayClick}>
      <ModalWrapper>

        {title && (
          <Header>
            {
              typeof title === 'string'
                ? <Heading>{title}</Heading>
                : title
            }
          </Header>
        )}

        <Body>{children}</Body>

        {buttons && (
          <Footer>
            {buttons}
          </Footer>
        )}
      </ModalWrapper>
    </Overlay>
  )
}
