import React, { useState } from 'react'

import User from '../../../models/User'
import { listUsers } from '../../../api/users'
import TablePage from '../../../components/organisms/TablePage'
import { Column } from '../../../components/organisms/Table'
import { useCreateModal } from '../components/useCreateModal'
import { useEditModal, EditResult } from '../components/useEditModal'
import { nullify, useAbortableEffect, useMessages } from '../../../utils'

const search = (needle: string, haystack: User[]) => haystack
  .filter(
    user => user.username.toLowerCase().includes(needle.toLowerCase())
    || user.email.toLowerCase().includes(needle.toLowerCase())
  )

const getField = (column?: string) => {
  switch (column) {
    case 'ID':
      return 'id'
    case 'Username':
      return 'username'
    case 'Email':
      return 'email'
    case 'Role':
      return 'role.role_name'
  }
  return undefined
}

export default function Users () {
  const [userData, setUserData] = useState<User[]>([])
  const { clearMessage, dispatchFailure } = useMessages()
  const editModal = useEditModal()
  const createModal = useCreateModal()

  useAbortableEffect((isMounted) => {
    clearMessage()
    listUsers()
      .then(result => isMounted(() => setUserData(result)))
      .catch(e => {
        dispatchFailure('Retrieving users failed')
        console.log(e)
      })
  }, [])

  const onCreate = () => createModal().then(
    (user: User) => setUserData(userData.concat(user))
  ).catch(nullify)

  const onEdit = (toEdit: User) => editModal(toEdit).then((result: EditResult) => {
    if (result.updated) {
      setUserData(
        userData.map(user => {
          if (result.updated?.id === user.id) {
            return result.updated
          } else {
            return user
          }
        })
      )
    }

    if (result.deleted) {
      setUserData(userData.filter(user => user.id !== result.deleted?.id))
    }
  }).catch(nullify)

  const columns: Column<User>[] = [
    {
      header: 'ID',
      content: (item: User) => item.id
    },
    {
      header: 'Username',
      content: item => item.username
    },
    {
      header: 'Email',
      content: item => item.email
    },
    {
      header: 'Role',
      content: item => item.role?.role_name || 'unknown'
    },
    {
      header: '',
      content: 'edit',
      isButton: true,
      onClick: onEdit
    }
  ]

  return (
    <>
      <TablePage
        description='All users in the database'
        data={userData}
        columns={columns}
        getField={getField}
        search={search}
        onCreate={onCreate} />
    </>
  )
}
