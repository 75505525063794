import React, { ReactNode } from 'react'
import { IndexLink, Link, withRouter, InjectedRouter } from 'react-router'

interface Location {
  pathname: string
}

interface NavItemProps {
  router?: InjectedRouter
  to: string
  index?: boolean
  children: ReactNode
  icon?: ReactNode
  location?: Location
}

const matchParts = (check: string, current: string) => {
  if (check[0] === '/') check = check.substring(1)
  if (current[0] === '/') current = current.substring(1)

  const checkParts = check.split('/')
  const currentParts = current.split('/')

  return checkParts.every((item: string, index) => item === currentParts[index])
}

export default withRouter<NavItemProps>(
  function NavItem ({ router, to, index, children, icon, location }: NavItemProps) {
    let active = false

    if (router?.isActive('/', true) && index) {
      active = true
    } else if (location) {
      active = matchParts(to, location.pathname)
    }

    const LinkComponent = index ? IndexLink : Link

    return (
      <li className={`navbar_item ${active ? 'active' : ''}`}>
        <LinkComponent to={to}>
          {icon && <span className='navbar_icon'>{icon}</span>}
          <span className='navbar_text'>
            {children}
          </span>
        </LinkComponent>
      </li>
    )
  }
)
