import React from 'react'
import { Grid, Button } from '@exivity/ui'

import './index.css'

interface PageNavigationProps {
  page: number
  max: number
  setPage: (newPage: number) => void
}

export default function PageNavigation ({ page, max, setPage }: PageNavigationProps) {
  return (
    <Grid className='page-navigation'>
      <Grid.Item>
        {
          page > 1 && (
            <Button tiny filledOutline secondary onClick={() => setPage(page - 1)}>
              {'<'}
            </Button>
          )
        }
      </Grid.Item>
      <Grid.Item>
        <span className='page-number'>
          <b>{page}/{max}</b>
        </span>
      </Grid.Item>
      <Grid.Item>
        {
          page < max && (
            <Button tiny filledOutline secondary onClick={() => setPage(page + 1)}>
              {'>'}
            </Button>
          )
        }
      </Grid.Item>
    </Grid>
  )
}
