import { useModal } from '../../../components/molecules/Modal'
import User from '../../../models/User'

export function useDeleteConfirmationModal () {
  const modal = useModal()

  return (user: User) => modal.warning({
    title: `Are you sure you want to delete the user ${user.username}?`,
    onOutsideClick: 'reject'
  })
}
