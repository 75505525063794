import Client from '../../models/Client'
import getUrl from '../utils/getUrl'
import combineIncluded from '../utils/combineIncluded'
import { getAuth } from '../authentication'

export default async function (): Promise<Client[]> {
  const url = getUrl('clients?include=created_by,updated_by')

  const opts = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getAuth()
    }
  }

  const resp = await fetch(url, opts)

  if (resp.status !== 200) {
    throw new Error(`GET clients returned ${resp.status}:\n${await resp.text()}`)
  }

  const json = await resp.json()

  return json.data.map(
    (item: { attributes: Client }) => combineIncluded(item, json.included)
  )
}
