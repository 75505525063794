import React, { useState, useEffect } from 'react'
import { Input, Button, Flex } from '@exivity/ui'

import { useMessages } from '../../../utils'
import User from '../../../models/User'
import Role from '../../../models/Role'
import { CreateUserArgs, createUser } from '../../../api/users'
import { useModal } from '../../../components/molecules/Modal'

import RoleSelect from './RoleSelect'

type Resolve = (user: Partial<CreateUserArgs>) => void

const CreateUser = ({ onResolve }: { onResolve: Resolve }) => {
  const [role, setRole] = useState<Role | null>(null)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [encryptionKey, setEncryptionKey] = useState('')
  const [ownEncryptionKey, setOwnEncryptionKey] = useState('')

  useEffect(() => {
    onResolve({
      role_id: role?.id,
      username,
      email,
      password,
      encryption_key: encryptionKey,
      own_encryption_key: ownEncryptionKey
    })
  }, [role, username, email, password, encryptionKey, ownEncryptionKey])

  return (
    <Flex justifyContent='space-around' direction='column' height={300}>
      <Input
        placeholder='username'
        value={username}
        textAlign=''
        required
        onChange={setUsername} />
      <Input
        placeholder='email'
        value={email}
        textAlign=''
        required
        onChange={setEmail} />
      <Input
        placeholder='password'
        value={password}
        textAlign=''
        required
        onChange={setPassword} />
      <Input
        placeholder='new encryption key'
        value={encryptionKey}
        textAlign=''
        required
        onChange={setEncryptionKey} />
      <RoleSelect
        value={role}
        onChange={(role: Role) => setRole(role)} />
      <br />
      <Input
        placeholder='your encryption key'
        value={ownEncryptionKey}
        textAlign=''
        required
        onChange={setOwnEncryptionKey} />
    </Flex>
  )
}

export function useCreateModal () {
  const modal = useModal()
  const [disabled, setDisabled] = useState(false)
  const { clearMessage, dispatchFailure, dispatchSuccess } = useMessages()

  return () => modal.custom({
    title: 'Create a new user',
    onOutsideClick: 'reject',
    children: (toResolve: Resolve) => (
      <CreateUser onResolve={toResolve} />
    ),
    buttons: (
      resolve: (res: User) => void, reject,
      { toResolve: userArgs }: { toResolve: Partial<CreateUserArgs> }
    ) => (
      <>
        <Button.Cancel key='cancel' onClick={() => reject()} />
        <Button.Create key='create' disabled={disabled} onClick={() => {
          if (
            !userArgs.email
            || !userArgs.password
            || !userArgs.role_id
            || !userArgs.username
            || !userArgs.encryption_key
            || !userArgs.own_encryption_key
          ) {
            return
          }

          setDisabled(true)
          clearMessage()

          createUser(userArgs as CreateUserArgs).then((user: User) => {
            dispatchSuccess(`User "${user.username}" has been created`)
            resolve(user)
            setDisabled(false)
          }).catch(e => {
            dispatchFailure(`Creating user "${userArgs.username}" failed`)
            console.log(e)
            setDisabled(false)
          })
        }} />
      </>
    )
  })
}
